<template>
  <div>
    <!--Header-->
    <header class="header_style2 nav-stacked affix-top" data-spy="affix" data-offset-top="1">
      <!-- Navigation -->
      <nav id="navigation_bar" class="navbar navbar-expand-lg">
        <div class="container">
      <div class="row header-row">
          <div class="navbar-header">
            <div class="logo">
              <img :src="require('@/assets/images/midlandautosales-leicester-logo.png')" class="logo-img" style="cursor: pointer;" alt="image" @click="navigateHome()" />
            </div>

            <b-button v-b-toggle.navigation id="menu_slide" class="navbar-toggler"><i class="fa fa-bars"></i></b-button>
          </div>
          <b-collapse id="navigation" class="collapse navbar-collapse">
            <ul class="nav navbar-nav">
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/pre-owned">Pre-Owned Vehicles</router-link>
              </li>
              <li>
                <router-link to="/warranty">Warranty</router-link>
              </li>
              <li>
                <router-link to="/sellyourcar">Sell Your Car</router-link>
              </li>
              <li>
                <router-link to="/contactus">Contact Us</router-link>
              </li>
            </ul>
          </b-collapse>
          
          <div class="header_wrap">
            <!-- <div class="user_login">
              <ul>
                <li class="dropdown"> <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-user-circle" aria-hidden="true"></i></a>
                  <ul class="dropdown-menu">
                    <li><a href="profile-settings.html">Profile Settings</a></li>
                    <li><a href="my-vehicles.html">My Vehicles</a></li>
                    <li><a href="post-vehicle.html">Post a Vehicle</a></li>
                    <li><a href="#">Sign Out</a></li>
                  </ul>
                </li>
              </ul>
            </div>


            
            

            <div class="login_btn"> <a href="#loginform" class="btn btn-xs uppercase" data-toggle="modal" data-dismiss="modal">Login / Register</a> </div> -->
          </div>
          </div>
        </div>
      </nav>
      <!-- Navigation end -->
      
    </header>
    <!-- /Header -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Header',
  methods: {
    navigateHome() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
</style>
